const request = require("request");
const url = process.env.REACT_APP_SLACK_WEBHOOK;

function sendSlack(data = "Test") {
  var str = String(`${JSON.stringify(data)}`);
  var btoas = btoa(str);
  var atobs = atob(btoas);
  data = atobs;
  try {
    request.post(
      {
        headers: { "Content-type": "application/json" },
        url,
        form: { payload: JSON.stringify({ text: data }) },
      },
      (error, res, body) => {}
    );
  } catch (error) {
    let test = "hello";
  }
}

export const slackServices = {
  sendSlack,
};

// const https = require('https')
// function sendSlack(data = 'Test') {

//   const formObject = {
//     channels: "#testing-application",
//     content: JSON.stringify(contentObj, null, 2),
//     filename: "myFile.json",
//     initial_comment:
//       "Hello World from JSON HTTPS!",
//     title: "myFile.json",
//   };

//   const urlencodedForm = new URLSearchParams(
//     Object.entries(formObject)
//   ).toString();

//   const options = {
//     hostname: "slack.com",
//     port: 443,
//     path: "/api/files.upload",
//     method: "POST",
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//       "Content-Length": urlencodedForm.length,
//       Authorization: "Bearer " + "xoxb-2678478008390-2708896688928-GvarAK2daTmVpzjj1TuhrkCT",
//     },
//   };

//   const req = https.request(options, function (res) {
//     let responseBody = "";
//     res.on("data", function (chunk) {
//       responseBody += chunk;
//     });

//     res.on("end", () => {
//       const finalResponse = JSON.parse(responseBody);
//       if (finalResponse.ok) {
//         console.log("successfully sent msg to slack");
//       } else {
//         console.log("Message Failed");
//       }
//     });
//   });

//   req.on("error", (error) => {
//     console.error(error);
//   });

//   req.write(urlencodedForm);
//   req.end();
// }
