import { services } from "../services";
import nft1155ABI from "../contractData/abis/nft1155.json";
import nft721ABI from "../contractData/abis/nft721.json";
import proxyAbi from "../contractData/abis/carnyproxy.json";
import {
  getCurrentChainContractAddress,
  getCurrentChainId,
} from "../helper/contracts";
import chains from "../Assets/files/chains.json";
import { isMobile } from "react-device-detect";

function fetchUserBalances(data) {
  return {
    type: "FETCH_USER_BALANCES", // dispatch user login event
    data: data,
  };
}

function setDispatchData(data, type) {
  return { data: data, type: type };
}

// get defi categories
function getNetworkId() {
  return (dispatch) => {
    const response = services.getNetworkId();
    response.then((promise) => {
      dispatch(setDispatchData(promise, "FETCH_NETWORK_ID"));
    });
  };
}

function getWeb3(val) {
  if (val) {
    return (dispatch) => {
      dispatch(setDispatchData(null, "FETCH_WEB3_DATA"));
    };
  } else
    return (dispatch) => {
      const response = services.getWeb3();
      response.then((promise) => {
        if (promise?.accounts[0]) {
          dispatch(setDispatchData(promise, "FETCH_WEB3_DATA"));
        } else {
          if (!isMobile && !localStorage.getItem("connectWithQR")) {
            if (localStorage.getItem("theme") == "dark") {
              localStorage.clear();
              localStorage.setItem("theme", "dark");
            }
          }
          // console.log('errorrrr in actions');
        }
      });
    };
}

function enabledWalletConnect() {
  return (dispatch) => {
    const response = services.enabledWalletConnect();
    response.then((promise) => {
      if (promise) {
        dispatch(setDispatchData(promise, "FETCH_WEB3_DATA"));
      } else {
        // console.log('error in actions');
      }
    });
  };
}
function enableMetamask() {
  return (dispatch) => {
    const response = services.enableMetamask();
    response.then((promise) => {
      // console.log("present");
      if (!promise.error) {
        // console.log("this is new actions", promise);
        dispatch(setDispatchData(promise, "FETCH_WEB3_DATA"));
      } else {
        // console.log("i am in error", promise);
        dispatch(setDispatchData(promise, "FETCH_WEB3_DATA_ERROR"));
      }
    });
  };
}
function getUserBalances(userAddress) {
  return (dispatch) => {
    const response = services.getUserBalances(userAddress);
    response.then((promise) => {
      if (promise) {
        dispatch(fetchUserBalances(promise));
      } else {
        // console.log('error in actions');
      }
    });
  };
}

function getNFTContractInstance(key) {
  const nftContractAddress = getCurrentChainContractAddress(key);
  return (dispatch) => {
    const response = services.getContractInstance(key==721 ? nft721ABI : nft1155ABI, nftContractAddress);
    response.then((promise) => {
      // console.log(promise);
      if (promise) {
        dispatch(setDispatchData(promise, "NFT_CONTRACT_INSTANCE"));
      } else {
        // console.log('error in actions');
      }
    });
  };
}

function getCarnyProxyContractInstance() {
  const proxyContractAddress = getCurrentChainContractAddress("proxy");
  return (dispatch) => {
    const response = services.getContractInstance(
      proxyAbi,
      proxyContractAddress
    );
    response.then((promise) => {
      // console.log(promise);
      if (promise) {
        dispatch(setDispatchData(promise, "CARNY_PROXY_CONTRACT_INSTANCE"));
      } else {
        // console.log('error in actions');
      }
    });
  };
}

function getCurrentChainCurrency(chainId = null) {
  let currenct = findCurrency(chainId);

  return {
    type: "FETCH_CURRENT_CURRENCY",
    data: currenct ? currenct.nativeCurrency.symbol : "ETH",
  };
}

function findCurrency(chainId = null) {
  return chains.find((chain) => {
    if (chainId && chainId == chain.chainId) {
      return chain.nativeCurrency.symbol;
    }

    if (!chainId && chain.chainId == getCurrentChainId()) {
      return chain.nativeCurrency.symbol;
    }
  });
}

export const web3Actions = {
  getNetworkId,
  getWeb3,
  enableMetamask,
  getUserBalances,
  getNFTContractInstance,
  getCarnyProxyContractInstance,
  enabledWalletConnect,
  getCurrentChainCurrency,
  findCurrency,
};
