const initialState = {
  balance: 0,
};

const accountReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "ACCOUNT_UPDATE_BALANCE":
      return {
        ...state,
        balance: payload,
      };
    default:
      return state;
  }
};

export default accountReducer;
