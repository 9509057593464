import { isMobile } from "react-device-detect";

const addresses = {
  721: {
    [process.env.REACT_APP_BINANCE_CHAIN_ID]:
      process.env.REACT_APP_BINANCE_TOKEN_721_ADDRESS,
    [process.env.REACT_APP_ETHEREUM_CHAIN_ID]:
      process.env.REACT_APP_ETHEREUM_TOKEN_721_ADDRESS,
    [process.env.REACT_APP_AVALANCE_CHAIN_ID]:
      process.env.REACT_APP_AVALANCE_TOKEN_721_ADDRESS,
    [process.env.REACT_APP_POLYGON_CHAIN_ID]:
      process.env.REACT_APP_POLYGON_TOKEN_721_ADDRESS,
  },
  1155: {
    [process.env.REACT_APP_BINANCE_CHAIN_ID]:
      process.env.REACT_APP_BINANCE_TOKEN_1155_ADDRESS,
    [process.env.REACT_APP_ETHEREUM_CHAIN_ID]:
      process.env.REACT_APP_ETHEREUM_TOKEN_1155_ADDRESS,
    [process.env.REACT_APP_AVALANCE_CHAIN_ID]:
      process.env.REACT_APP_AVALANCE_TOKEN_1155_ADDRESS,
    [process.env.REACT_APP_POLYGON_CHAIN_ID]:
      process.env.REACT_APP_POLYGON_TOKEN_1155_ADDRESS,
  },
  proxy: {
    [process.env.REACT_APP_BINANCE_CHAIN_ID]:
      process.env.REACT_APP_BINANCE_CARNY_MARKETPLACE_ADDRESS,
    [process.env.REACT_APP_ETHEREUM_CHAIN_ID]:
      process.env.REACT_APP_ETHEREUM_CARNY_MARKETPLACE_ADDRESS,
    [process.env.REACT_APP_AVALANCE_CHAIN_ID]:
      process.env.REACT_APP_AVALANCE_CARNY_MARKETPLACE_ADDRESS,
    [process.env.REACT_APP_POLYGON_CHAIN_ID]:
      process.env.REACT_APP_POLYGON_CARNY_MARKETPLACE_ADDRESS,
  },
};
const checkNetwork = async () => {
  if (window.ethereum && !isMobile && !localStorage.getItem("connectWithQR")) {
    const currentChainId = await window.ethereum.request({
      method: "eth_chainId",
    });

    return parseInt(currentChainId, 16);
  }

  if (
    isMobile &&
    window.ethereum &&
    (window.ethereum.isMetaMask || window.ethereum.isTrust)
  ) {
    if (!window.ethereum?._state?.isConnected) {
      return await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (res) => {
          return await window.ethereum.request({method: 'eth_chainId'})
        });
    } else {
      return window.ethereum.request({method: 'eth_chainId'})
    }
  } else if (isMobile || localStorage.getItem("connectWithQR")) {
    if (isValidChain(localStorage.getItem("chainId"))) {
      return localStorage.getItem("chainId");
    } else {
      return process.env.REACT_APP_DEFAULT_CHAIN_ID;
    }
  }
};

const rpcs = {
  [process.env.REACT_APP_ETHEREUM_CHAIN_ID]:
    process.env.REACT_APP_ETHEREUM_CHAIN_NET,
  [process.env.REACT_APP_BINANCE_CHAIN_ID]:
    process.env.REACT_APP_BINANCE_CHAIN_NET,
  [process.env.REACT_APP_AVALANCE_CHAIN_ID]:
    process.env.REACT_APP_AVALANCE_CHAIN_NET,
  [process.env.REACT_APP_POLYGON_CHAIN_ID]:
    process.env.REACT_APP_POLYGON_CHAIN_NET,
};

const tokenAddresses = {
  [process.env.REACT_APP_ETHEREUM_CHAIN_ID]:
    "0x0000000000000000000000000000000000000000",
  [process.env.REACT_APP_BINANCE_CHAIN_ID]:
    "0x0000000000000000000000000000000000000000",
};

const coingeckoNames = {
  [process.env.REACT_APP_ETHEREUM_CHAIN_ID]: "ethereum",
  [process.env.REACT_APP_BINANCE_CHAIN_ID]: "binancecoin",
  [process.env.REACT_APP_AVALANCE_CHAIN_ID]: "avalanche-2",
  [process.env.REACT_APP_POLYGON_CHAIN_ID]: "matic-network",
};

const paymentTokens = {
  [process.env.REACT_APP_ETHEREUM_CHAIN_ID]: process.env.REACT_APP_WRAPPED_ETH,
  [process.env.REACT_APP_BINANCE_CHAIN_ID]: process.env.REACT_APP_WRAPPED_BNB,
};

const paymentTokensSymbols = {
  [process.env.REACT_APP_ETHEREUM_CHAIN_ID]: "WETH",
  [process.env.REACT_APP_BINANCE_CHAIN_ID]: "WBNB",
};

let currentChainID = null;

(() => {
  new Promise(function (resolve, reject) {
    resolve(checkNetwork());
  }).then((res) => {
    if (res != null) {
      currentChainID = res;
      localStorage.setItem("chainId", res);
    }
  });
})();

if (isMobile || !window.ethereum || localStorage.getItem("connectWithQR")) {
  currentChainID =
    localStorage.getItem("chainId") ?? process.env.REACT_APP_DEFAULT_CHAIN_ID;
} else {
  currentChainID =
    currentChainID ?? localStorage.getItem("chainId");
}

export function getCurrentChainId() {
  return currentChainID;
}

export function getCurrentChainContractAddress(chainName, chainId = null) {
  if (currentChainID) return addresses[chainName][chainId ?? currentChainID];
  return addresses[chainName][process.env.REACT_APP_DEFAULT_CHAIN_ID];
}

export function getCurrentChainRpc() {
  if (currentChainID) return rpcs[currentChainID];
  return rpcs[process.env.REACT_APP_DEFAULT_CHAIN_ID];
}

export function getCurrentTokenAddress() {
  // if(currentChainID) return tokenAddresses[currentChainID];
  return "0x0000000000000000000000000000000000000000";
}

export function isValidChain(chainId) {
  return [
    +process.env.REACT_APP_BINANCE_CHAIN_ID,
    +process.env.REACT_APP_ETHEREUM_CHAIN_ID,
    +process.env.REACT_APP_AVALANCE_CHAIN_ID,
    +process.env.REACT_APP_POLYGON_CHAIN_ID,
  ].includes(+chainId);
}

export function isValidChainHex(chainId) {
  return [
    "0x" + parseInt(process.env.REACT_APP_BINANCE_CHAIN_ID).toString(16),
    "0x" + parseInt(process.env.REACT_APP_ETHEREUM_CHAIN_ID).toString(16),
    "0x" + parseInt(process.env.REACT_APP_AVALANCE_CHAIN_ID).toString(16),
    "0x" + parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID).toString(16),
  ].includes(chainId);
}

export function getCurrentCoingeckoCurrencyName(chainId = null) {
  if (chainId || currentChainID)
    return coingeckoNames[chainId ?? currentChainID];
  return coingeckoNames[process.env.REACT_APP_DEFAULT_CHAIN_ID] ?? "ethereum";
}

export function getCurrentChainPaymentToken(chainId = null) {
  return paymentTokens[chainId ?? currentChainID];
}

export const isCurrentChain = (chainId) => {
  return currentChainID == chainId;
};

export const isCarnyNftContractAddress = (address) => {
  return address == getCurrentChainContractAddress(721, currentChainID)?.toLowerCase() || address == getCurrentChainContractAddress(1155, currentChainID)?.toLowerCase();
};

export const getCurrentChainPaymentTokenSymbol = (chainId = null) => {
  return paymentTokensSymbols[chainId ?? currentChainID];
};
