function toggleStyle(st = "") {
  if (localStorage.getItem("theme") == "dark") {
    localStorage.setItem("theme", "light");
    document.body.className = "";
  } else {
    localStorage.setItem("theme", "dark");
    document.body.className = "dark";
  }

  // const cookies = document.cookie.split(';');
  // cookies.forEach(c => {
  //     if(c.includes('theme=dark')){
  //         document.cookie = "theme=light";
  //         document.body.className = 'light';
  //         st = 'light';
  //     }
  //     else {
  //         document.cookie = 'theme=dark';
  //         document.body.className = 'dark';
  //         // document.getElementById('switcher').setAttribute('checked', true);
  //         st = 'dark';
  //     }
  // })

  return {
    type: "TOGGLE_STYLE",
    data: st,
  };
}

export const styleActions = {
  toggleStyle,
};
