var Media = {};

Media = {
  xl: "@media(max-width:1600px)",
  lg: "@media(max-width:1366px)",
  md: "@media(max-width:1024px)",
  sm: "@media(max-width:767px)",
  xs: "@media(max-width:500px)",
};

export default Media;
