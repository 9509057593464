import React from "react";
import ReactDOM from "react-dom";
import "react-notifications/lib/notifications.css";
import "../public/css/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import Wrapper from "./Component/wrapper";
import { NotificationContainer } from "react-notifications";

const renderApp = () => {
  ReactDOM.render(
    <Wrapper>
      <Provider store={store}>
        <NotificationContainer />
        <App />
      </Provider>
    </Wrapper>,
    document.getElementById("root")
  );
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', renderApp);
} else {
  renderApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
