export const theme = (isDarkTheme) => {
  return {
    // text colors
    color01: isDarkTheme ? "#fff" : "#000",

    // border colors
    border01: isDarkTheme ? "#5a5e67" : "#d6d6d6",

    // background colors
    bodybg01: isDarkTheme ? "#414851" : "#d5ecff",
  };
};
