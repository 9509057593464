import { isMobile } from "react-device-detect";

export function fetchNetworkId(state = null, action) {
  switch (action.type) {
    case "FETCH_NETWORK_ID":
      return action.data;
    default:
      return state;
  }
}
export function fetchNFTContractInstance(state = null, action) {
  switch (action.type) {
    case "NFT_CONTRACT_INSTANCE":
      return action.data;
    default:
      return state;
  }
}

export function fetchCarnyProxyContractInstance(state = null, action) {
  switch (action.type) {
    case "CARNY_PROXY_CONTRACT_INSTANCE":
      return action.data;
    default:
      return state;
  }
}

export function fetchWeb3Data(
  state = {
    isLoggedIn: localStorage.getItem("avangartAuthToken") ? true : false,
    accounts:
      isMobile || localStorage.getItem("connectWithQR")
        ? [localStorage.getItem("userAddress")]
        : [],
  },
  action
) {
  switch (action.type) {
    case "FETCH_WEB3_DATA":
      return action.data;
    case "FETCH_WEB3_DATA_ERROR":
      return action.data;
    default:
      return state;
  }
}

export function currentCurrency(state = null, action) {
  switch (action.type) {
    case "FETCH_CURRENT_CURRENCY":
      return action.data;
    case "FETCH_CURRENT_CURRENCY_ERROR":
      return action.data;
    default:
      return state;
  }
}
