import { backendServices } from "./backend.service";
import { cookieService } from "./cookie.service";
import { web3Services } from "./web3.service";
import { slackServices } from "./slack.service";

export const services = {
  ...backendServices,
  ...web3Services,
  ...cookieService,
  ...slackServices,
};
