import Web3 from "web3";
import {
  getCurrentChainContractAddress,
} from "./helper/contracts";
import nftAbi from "../src/contractData/abis/nft1155.json";
import { isMobile } from "react-device-detect";

let web3 = new Web3();
let web3ForQuery = null;

var contractAddress = null;
(function () {
  contractAddress = getCurrentChainContractAddress("721");
})();

const metamaskConnectInit = () => {
  // Check if Web3 has been injected by the browser (Mist/MetaMask).
  return new Promise((resolve, reject) => {
    if (
      (typeof window.web3 !== "undefined" &&
        !isMobile &&
        !localStorage.getItem("connectWithQR")) ||
      (typeof window.ethereum !== "undefined" &&
        (window.ethereum.isMetaMask || window.ethereum.isTrust) &&
        isMobile)
    ) {
      // Use Mist/MetaMask's provider.
      web3 = new Web3(window.ethereum);
      web3ForQuery = new Web3(new web3.eth.Contract(nftAbi, contractAddress));

      localStorage.setItem("walletConnect", 0);
      resolve(true);
    } else {
      // Handle the case where the user doesn't have web3. Probably
      // show them a message telling them to install Metamask in
      // order to use the app.

      resolve(false);
    }
  });
};

if (!web3 || !web3.currentProvider) {
  metamaskConnectInit();
}

export {
  web3,
  web3ForQuery,
  metamaskConnectInit,
};
